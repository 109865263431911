/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "justify-content",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#justify-content",
    "aria-label": "justify content permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Justify Content"), "\n", React.createElement(_components.p, null, "Utilities for controlling how flex and grid items are positioned along a container's main axis."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "justifyContent={keyword}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "justify-content: {keyword};"))))), "\n", React.createElement(_components.h2, {
    id: "start",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start",
    "aria-label": "start permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "justifyContent=\"flex-start\""), " to justify items against the start of the container's main axis:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=fuchsia"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"flex-start\" spaceX={4}>\n      {Array.from({ length: 3 }, (_, index) => index + 1).map((value) => (\n        <x.div\n          key={value}\n          h={16}\n          w={16}\n          borderRadius=\"md\"\n          bg=\"fuchsia-500\"\n          color=\"white\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          fontSize=\"2xl\"\n          fontWeight=\"extrabold\"\n        >\n          {value}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"flex\" justifyContent=\"flex-start\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "center",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#center",
    "aria-label": "center permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Center"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "justifyContent=\"center\""), " to justify items along the center of the container's main axis:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=indigo"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"center\" spaceX={4}>\n      {Array.from({ length: 3 }, (_, index) => index + 1).map((value) => (\n        <x.div\n          key={value}\n          h={16}\n          w={16}\n          borderRadius=\"md\"\n          bg=\"indigo-500\"\n          color=\"white\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          fontSize=\"2xl\"\n          fontWeight=\"extrabold\"\n        >\n          {value}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"flex\" justifyContent=\"center\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "end",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#end",
    "aria-label": "end permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "End"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "justifyContent=\"flex-end\""), " to justify items against the end of the container's main axis:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"flex-end\" spaceX={4}>\n      {Array.from({ length: 3 }, (_, index) => index + 1).map((value) => (\n        <x.div\n          key={value}\n          h={16}\n          w={16}\n          borderRadius=\"md\"\n          bg=\"amber-500\"\n          color=\"white\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          fontSize=\"2xl\"\n          fontWeight=\"extrabold\"\n        >\n          {value}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"flex\" justifyContent=\"flex-end\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "space-between",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#space-between",
    "aria-label": "space between permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Space between"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "justifyContent=\"space-between\""), " to justify items along the container's main axis such that there is an equal amount of space between each item:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"space-between\">\n      {Array.from({ length: 3 }, (_, index) => index + 1).map((value) => (\n        <x.div\n          key={value}\n          h={16}\n          w={16}\n          borderRadius=\"md\"\n          bg=\"emerald-500\"\n          color=\"white\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          fontSize=\"2xl\"\n          fontWeight=\"extrabold\"\n        >\n          {value}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"flex\" justifyContent=\"space-between\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "space-around",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#space-around",
    "aria-label": "space around permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Space around"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "justifyContent=\"space-around\""), " to justify items along the container's main axis such that there is an equal amount of space on each side of each item:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=red"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"space-around\">\n      {Array.from({ length: 3 }, (_, index) => index + 1).map((value) => (\n        <x.div\n          key={value}\n          h={16}\n          w={16}\n          borderRadius=\"md\"\n          bg=\"red-500\"\n          color=\"white\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          fontSize=\"2xl\"\n          fontWeight=\"extrabold\"\n        >\n          {value}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"flex\" justifyContent=\"space-around\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "space-evenly",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#space-evenly",
    "aria-label": "space evenly permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Space evenly"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "justifyContent=\"space-evenly\""), " to justify items along the container's main axis such that there is an equal amount of space around each item, but also accounting for the doubling of space you would normally see between each item when using ", React.createElement(_components.code, null, "justifyContent=\"space-around\""), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"space-evenly\">\n      {Array.from({ length: 3 }, (_, index) => index + 1).map((value) => (\n        <x.div\n          key={value}\n          h={16}\n          w={16}\n          borderRadius=\"md\"\n          bg=\"light-blue-500\"\n          color=\"white\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          fontSize=\"2xl\"\n          fontWeight=\"extrabold\"\n        >\n          {value}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div display=\"flex\" justifyContent=\"space-evenly\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To justify flex items at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "justifyContent={{ md: \"center\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "justifyContent=\"center"), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div display=\"grid\" justifyContent={{ md: 'center' }}>\n  {/* ... */}\n</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
